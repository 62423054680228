export const styleBalanceContainer = {
  bgcolor: '#231E3A',
  borderRadius: '20px',
  flex: 1,
  position: 'relative',
  height: '196px',
  overflow: 'hidden',
};

export const hoverMenuBg = 'linear-gradient(90deg, #F0CDFF 0%, #CFE7FE 100%)';

// export const ColorfulBg = '#F16529';//副-1：E53935；副-2：E5C62ED；副-1：E53935；副-1：E53935；

export function ColorfulBg() {
  try {
    let colorsConfig = localStorage?.getItem('colorsConfig') || '{}';
    let colorJson = JSON.parse(colorsConfig);

    return colorJson?.dominant || colorJson?.domain || '#F16529';
  } catch (error) {
    return '#F16529';
  }
}

export function colorConfig() {
  return getColorConfig();
}

const getColorConfig = () => {
  try {
    let colorsConfig = localStorage?.getItem('colorsConfig') || '{}';
    let colorJson = JSON.parse(colorsConfig);

    return {
      domain: colorJson?.dominant || colorJson?.domain || '#F16529',
      background: colorJson?.background || '#212933',
      secondary: colorJson?.secondary || '#8A97FC',
    };
  } catch (error) {
    return {
      domain: '#F16529',
      background: '#212933',
      secondary: '#8A97FC',
    };
  }
};

export const getTabBg = () => {
  const { domain, background } = getColorConfig();

  // linear-gradient(180deg, #49B6FF 0%, #356F99 25%, #2B4C66 50%, #212933 100%), #212933

  const midColor = getMiddleColor(domain, background);
  const midColor2 = getMiddleColor(midColor, background);

  return {
    background: `linear-gradient(180deg, ${domain} 0%, ${midColor} 25%, ${midColor2} 50%, ${background} 100%), ${background}`,
  };
};

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function rgbToHex(r: number, g: number, b: number) {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function getMiddleColor(color1: string, color2: string) {
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  if (!rgb1 || !rgb2) {
    return color1;
  }

  const middleRgb = {
    r: Math.round((rgb1.r + rgb2.r) / 2),
    g: Math.round((rgb1.g + rgb2.g) / 2),
    b: Math.round((rgb1.b + rgb2.b) / 2),
  };

  return rgbToHex(middleRgb.r, middleRgb.g, middleRgb.b);
}
