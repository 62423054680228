import ICON_CLOSE from '@/assets/icons/ic_close_snp.png';
import MONEY_REWARD from '@/assets/images/money-reword.png';
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';

interface Props {
  open: boolean;
  onClose: () => void;
  title?: string;
}

export const MoneyRewardDialog: React.FC<Props> = (props: Props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{ sx: { bgcolor: 'transparent', boxShadow: 'none' } }}
    >
      <Box pt="80px">
        <Box
          sx={{
            background: 'linear-gradient(0deg, #242123, #242123)',
            borderRadius: '24px',
          }}
        >
          <Box
            sx={{
              background:
                'linear-gradient(180deg, rgba(241, 238, 134, 0.25) 0%, rgba(241, 238, 134, 0) 100%)',
              textAlign: 'center',
              position: 'relative',
            }}
            px={2}
            py={2.5}
            width={280}
            borderRadius="24px"
          >
            <Box width="100%" height="120px"></Box>
            <Box
              sx={{
                position: 'absolute',
                top: -80,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 200,
                height: 200,
              }}
            >
              <img
                src={MONEY_REWARD}
                alt=""
                style={{ width: 200, height: 200 }}
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: 108,
                left: 0,
                right: 0,
                textAlign: 'center',
                fontSize: 16,
                lineHeight: 1,
                fontWeight: 700,
                fontStyle: 'italic',
                color: '#F1EE86',
              }}
            >
              congratulations
            </Box>
            <Typography
              mb={2.5}
              mt={1.5}
              textAlign="center"
              variant="h4"
              lineHeight={1}
              fontStyle={'italic'}
              fontWeight={700}
            >
              {props.title}
            </Typography>

            <Button
              variant="contained"
              sx={{ width: '200px', borderRadius: 999 }}
              onClick={props.onClose}
            >
              Receive
            </Button>
          </Box>
        </Box>

        <Box sx={{ mt: '3px', textAlign: 'center' }}>
          <IconButton size="small" onClick={props.onClose}>
            {/* <CloseRoundedIcon /> */}
            <img
              src={ICON_CLOSE}
              alt=""
              style={{
                width: 24,
                height: 24,
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};
