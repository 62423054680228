import GAME_IMAGE_DEFAULT from '@/assets/images/game-default.png';
import IMAGE_LOADING from '@/assets/images/img_loading.png';
import { Box } from '@mui/material';
import { CSSProperties } from 'react';
import useBoolean from '../hooks/useBoolean';

interface Props {
  src: string;
  alt: string;
  width?: number | string;
  height?: number | string;
  aspectRatio: string;
  defaultSrc?: string;
  style?: CSSProperties | undefined;
}

const ImageWithLoading: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useBoolean(true);
  const [error, setError] = useBoolean(false);

  return (
    <Box
      sx={{
        width: props.width,
        height: props.height,
        style: props.style,
        aspectRatio: props.aspectRatio,
        background: `url(${IMAGE_LOADING}) #142431 no-repeat`,
        // backgroundPosition: 'center',
        // backgroundRepeat: 'no-repeat',
        // backgroundColor: "rgba(255,255,255,.05)",
        overflow: 'hidden',
        // backgroundSize: '100%',
        backgroundSize: '200%',
        backgroundPositionY: '40%',
        animation: loading ? 'img-loading 1.2s linear infinite' : 'none',
        position: 'relative',
        borderRadius: '8px',
        '@keyframes img-loading': {
          '0%': {
            backgroundPositionX: '150%',
          },
          '100%': {
            backgroundPositionX: '-150%',
          },
        },
      }}
    >
      <img
        style={{
          position: 'absolute',
          width: '100%',
          height: 'auto',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          // opacity: loading ? 0 : 1,
        }}
        onLoad={setLoading.off}
        onError={setError.on}
        src={
          error
            ? props.defaultSrc
              ? props.defaultSrc
              : GAME_IMAGE_DEFAULT
            : props.src
        }
        alt={props.alt}
      />
    </Box>
  );
};

export default ImageWithLoading;
