import ICON_CLOSE from '@/assets/icons/ic_close_snp.png';
import Giftcode from '@/assets/images/Giftcode.png';
import Giftcode_pt from '@/assets/images/Giftcode_pt.png';
import redeeTopTxt from '@/assets/images/redeeTopTxt.png';
import redeeTopTxt_pt from '@/assets/images/redeeTopTxt_pt.png';
import reedeBtn from '@/assets/images/redeemBtn.png';
import reedeBtn_pt from '@/assets/images/redeemBtn_pt.png';
import reedeBg from '@/assets/images/reedeBg.png';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useLang from '@/hooks/useLang';
import services from '@/services';
import {
  Box,
  Dialog,
  IconButton,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { LanguageType } from '../../types';

interface Props {
  open: boolean;
  onClose: () => void;
  onRedee: (data: any) => void;
  pic?: string;
  title?: string;
  num?: number;
}

const RedeemDialog: React.FC<Props> = (props: Props) => {
  const [code, setCode] = useState<string>('');
  const { __ } = useLang();
  const { language } = useLang();
  const onClose = () => {
    props.onClose();
    setCode('');
  };

  const onRedee = async () => {
    if (!code) {
      return;
    }
    let res = await services.config.redeemCode(code);

    if (!res) {
      return;
    }
    // res = {
    //   status: 1,
    //   msg: '操作成功',
    //   data: [
    //     {
    //       id: '431057268285652992',
    //       prizeId: '403959970284195840',
    //       prizeType: 2,
    //       prizeName: {
    //         zhCN: '现金奖励8雷亚尔',
    //         enUS: 'Prêmios RS：8',
    //         ptBR: 'Premios：R$8',
    //       },
    //       prizeValue: {
    //         value: 8,
    //         orderRate: 100,
    //       },
    //       state: 2,
    //       addTime: 1712231261,
    //       sourceType: 1,
    //       pic: 'https://www.br-baby.com/api/uploads/404297361507237888.png',
    //       blurb: {},
    //       uId: '1000009',
    //     },
    //     {
    //       id: '431057268285652992',
    //       prizeId: '403959970284195840',
    //       prizeType: 2,
    //       prizeName: {
    //         zhCN: '现金奖励8雷亚尔',
    //         enUS: 'Prêmios RS：8',
    //         ptBR: 'Premios：R$8',
    //       },
    //       prizeValue: {
    //         value: 8,
    //         orderRate: 100,
    //       },
    //       state: 2,
    //       addTime: 1712231261,
    //       sourceType: 1,
    //       pic: 'https://www.br-baby.com/api/uploads/404297361507237888.png',
    //       blurb: {},
    //       uId: '1000009',
    //     },
    //     {
    //       id: '431057268285652992',
    //       prizeId: '403959970284195840',
    //       prizeType: 2,
    //       prizeName: {
    //         zhCN: '现金奖励8雷亚尔',
    //         enUS: 'Prêmios RS：8',
    //         ptBR: 'Premios：R$8',
    //       },
    //       prizeValue: {
    //         value: 8,
    //         orderRate: 100,
    //       },
    //       state: 2,
    //       addTime: 1712231261,
    //       sourceType: 1,
    //       pic: 'https://www.br-baby.com/api/uploads/404297361507237888.png',
    //       blurb: {},
    //       uId: '1000009',
    //     },
    //     {
    //       id: '431057268285652992',
    //       prizeId: '403959970284195840',
    //       prizeType: 2,
    //       prizeName: {
    //         zhCN: '现金奖励8雷亚尔',
    //         enUS: 'Prêmios RS：8',
    //         ptBR: 'Premios：R$8',
    //       },
    //       prizeValue: {
    //         value: 8,
    //         orderRate: 100,
    //       },
    //       state: 2,
    //       addTime: 1712231261,
    //       sourceType: 1,
    //       pic: 'https://www.br-baby.com/api/uploads/404297361507237888.png',
    //       blurb: {},
    //       uId: '1000009',
    //     },
    //     {
    //       id: '431057268285652992',
    //       prizeId: '403959970284195840',
    //       prizeType: 2,
    //       prizeName: {
    //         zhCN: '现金奖励8雷亚尔',
    //         enUS: 'Prêmios RS：8',
    //         ptBR: 'Premios：R$8',
    //       },
    //       prizeValue: {
    //         value: 8,
    //         orderRate: 100,
    //       },
    //       state: 2,
    //       addTime: 1712231261,
    //       sourceType: 1,
    //       pic: 'https://www.br-baby.com/api/uploads/404297361507237888.png',
    //       blurb: {},
    //       uId: '1000009',
    //     },
    //   ],
    //   total_page: 0,
    // };
    // res = {
    //   status: 1,
    //   msg: '操作成功',
    //   data: {
    //     ruleName: 'aaa',
    //     ruleDetails: [
    //       {
    //         targetValue: '100',
    //         ruleName: '当日累计充值金额',
    //         currentValue: 0,
    //       },
    //       {
    //         targetValue: '1000',
    //         ruleName: '历史金额',
    //         currentValue: 57,
    //       },
    //     ],
    //   },
    // };
    if (res?.data?.userActivityState === 3) {
      SnackbarUtils.warning(__('same tip'), void 0, 2500);
      return;
    }
    props.onRedee(res?.data || {});
    setCode('');
  };
  const onPaster = async () => {
    const text = await navigator?.clipboard?.readText();
    setCode(text);
  };
  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      PaperProps={{ sx: { bgcolor: 'transparent', boxShadow: 'none' } }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url(${reedeBg})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: '311px',
            height: '386px',
            borderRadius: '16px',
            alignItems: 'center',
          }}
        >
          <img
            src={language === LanguageType.EN ? redeeTopTxt : redeeTopTxt_pt}
            alt=""
            style={{
              width: '247px',
              height: language === LanguageType.EN ? '62px' : '58px',
              marginTop: '28px',
            }}
          />
          <img
            src={language === LanguageType.EN ? Giftcode : Giftcode_pt}
            alt=""
            style={{
              width: 'auto',
              height: '18px',
              marginTop: '43px',
            }}
          />
          <OutlinedInput
            value={code}
            onChange={(e) => setCode(e.target.value)}
            size="medium"
            sx={{
              height: '40px',
              width: '191px',
              bgcolor: '#F6C093',
              mt: '16px',
              color: '#1D2026',
            }}
            endAdornment={
              <Typography
                textAlign="center"
                variant="body1"
                fontWeight={500}
                sx={{ color: '#7E3A13' }}
                onClick={onPaster}
              >
                {__('paster')}
              </Typography>
            }
          />

          <img
            src={language === LanguageType.EN ? reedeBtn : reedeBtn_pt}
            alt=""
            style={{
              width: '159px',
              height: '40px',
              marginTop: '59px',
            }}
            onClick={onRedee}
          />
        </Box>

        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <IconButton size="small" onClick={onClose}>
            <img
              src={ICON_CLOSE}
              alt=""
              style={{
                width: 24,
                height: 24,
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RedeemDialog;
