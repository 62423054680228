import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { IGameCategoryImg, IGameItem } from '@/types/config';
import { hideScrollbarX } from '@/utils/cssStyles';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import GameFont from '../GameFont';
import Center from '../center';
import ImageWithLoading from '../image';
import { GameCard } from './GameList';

import 'swiper/css';
import 'swiper/css/pagination';
import EmptyData from '../EmptyData';
import HomeSectionContainer from './SectionContainer';
interface Props {
  title: string;
  desc?: string;
  icon?: IGameCategoryImg;
  bgImg?: IGameCategoryImg;
  list: IGameItem[];
  onClickMore?: () => void;
}

export const RecommendProviderGameList = (props: Props) => {
  const isMobile = useResponsive('down', 'md');
  if (isMobile) {
    return <RecommendProviderGameListMobile {...props} />;
  }
  return <RecommendProviderGameListWeb {...props} />;
};

export const RecommendProviderGameListWeb = (props: Props) => {
  const { __ } = useLang();
  const showList = props.list.slice(0, 8);

  return (
    <Box sx={{ position: 'relative', mt: '16px', pt: '24px' }}>
      {props?.icon?.square ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'block',
          }}
        >
          <img
            src={props?.icon?.square}
            alt=""
            style={{ width: '48px', height: '48px' }}
          />
        </Box>
      ) : null}
      <Box
        sx={{
          bgcolor: 'background.paper',
          pb: '12px',
          borderRadius: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url(${props?.bgImg?.vertocal})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '98px',
            mb: '12px',
          }}
        >
          <Center sx={{ mt: '32px' }} flexDirection="column">
            <Typography
              variant="subtitle1"
              // className="br-game-font"
              className="home-game-list-font"
              sx={{
                fontWeight: '700',
                px: 1,
                overflow: 'hidden',
                width: '100%',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                textShadow: '10px 3px 6px rgba(0, 0, 0, 0.16)',
              }}
            >
              {props?.title}
            </Typography>
            <Button
              sx={{
                height: '24px',
                color: 'text.secondary',
                bgcolor: 'background.neutral',
                borderRadius: '12px',
                fontSize: 12,
                fontWeight: 400,
                minWidth: 0,
                px: 1,
                mt: '8px',
                fontStyle: 'italic',
              }}
              variant="text"
              size="small"
              onClick={props.onClickMore}
            >
              {__('View All')}
            </Button>
          </Center>
        </Box>
        <Grid
          container
          gridRow={1}
          columns={24}
          spacing={{ xs: 1.5, sm: 2 }}
          sx={{ px: '12px' }}
        >
          {showList.map((item, index) => {
            return (
              <Grid item key={item.id} md={3}>
                <GameCard item={item} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export const RecommendProviderGameListMobile = (props: Props) => {
  const { __ } = useLang();
  const navigate = useNavigate();

  const showList = props.list.slice(0, 18);

  return (
    <Box sx={{ position: 'relative', mt: '16px', pt: '24px' }}>
      {props?.icon?.square ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'block',
          }}
        >
          <img
            src={props?.icon?.square}
            alt=""
            style={{ width: '48px', height: '48px' }}
          />
        </Box>
      ) : null}
      <Box
        sx={{
          bgcolor: 'background.paper',
          pb: '12px',
          borderRadius: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url(${props?.bgImg?.transverse})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '98px',
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
          }}
        >
          <Center sx={{ mt: '32px' }} flexDirection="column">
            <Typography
              variant="subtitle1"
              // className="br-game-font"
              sx={{
                fontWeight: '700',
                px: 1,
                overflow: 'hidden',
                width: '100%',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                textShadow: '10px 3px 6px rgba(0, 0, 0, 0.16)',
              }}
            >
              {props?.title}
            </Typography>
            <Button
              sx={{
                height: '24px',
                color: 'text.secondary',
                bgcolor: 'background.neutral',
                borderRadius: '4px',
                fontSize: 12,
                fontWeight: 400,
                minWidth: 0,
                px: 1,
                mt: '8px',
              }}
              variant="text"
              size="small"
              onClick={props.onClickMore}
            >
              {__('View All')}
            </Button>
          </Center>
        </Box>
        <Stack
          direction="row"
          spacing="10px"
          sx={{
            mt: '12px',
            pl: '12px',
            ...hideScrollbarX,
          }}
        >
          {showList.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{ cursor: 'pointer', position: 'relative' }}
                onClick={() => {
                  navigate('/game/info?id=' + item.id);
                }}
              >
                <ImageWithLoading
                  width={90}
                  height={119}
                  aspectRatio={'2/1'}
                  alt={item.game_name}
                  src={item.pic}
                />
                <Center
                  sx={{
                    bottom: '3px',
                    width: '100%',
                    overflow: 'hidden',
                    minHeight: '25%',
                    left: 0,
                    right: 0,
                    position: 'absolute',
                  }}
                >
                  <GameFont name={item.game_name}></GameFont>
                </Center>
              </Box>
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};

export const RecommendProviderGameListMobile2 = (props: Props) => {
  const showList = props.list.slice(0, 8);

  return (
    <Box mb={2.5}>
      <HomeSectionContainer
        title={props.title}
        desc={props.desc}
        onClickMore={props.onClickMore}
        total={props.list.length}
      >
        <Box>
          <Grid container columns={24} spacing={{ xs: 1.5, sm: 2 }}>
            {showList.map((item, index) => {
              return (
                <Grid item key={item.id} xs={6}>
                  <GameCard item={item} />
                </Grid>
              );
            })}
          </Grid>

          {props.list.length === 0 && (
            <Center py={2}>
              <EmptyData />
            </Center>
          )}
        </Box>
      </HomeSectionContainer>
    </Box>
  );
};
