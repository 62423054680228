import IMAGE_RECEIVED from '@/assets/icons/icon-received.png';
import { BoxDto, BoxStatus } from '@/hooks/useActivity';
import {
  Box,
  Grid,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
interface Props {
  list: Array<BoxDto>;
  current: number;
  onOpen: (ruleKey: number) => void;
}

export const WinList = (props: Props) => {
  const Header = (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={1.5}
      mb={1.5}
      width="100%"
    >
      <Typography
        variant="body2"
        color="text.secondary"
        fontSize={10}
        lineHeight={1}
      >
        Progresso atual / Objetivos
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        fontSize={10}
        lineHeight={1}
      >
        Incentivos
      </Typography>
    </Box>
  );

  if (!props.list.length) {
    return (
      <Box mt={2} width="100%">
        {Header}
        <Skeleton
          variant="rounded"
          width="100%"
          animation="wave"
          sx={{ height: 66, mb: 1.5 }}
        />
        <Skeleton
          variant="rounded"
          width="100%"
          animation="wave"
          sx={{ height: 66, mb: 1.5 }}
        />
        <Skeleton
          variant="rounded"
          width="100%"
          animation="wave"
          sx={{ height: 66 }}
        />
      </Box>
    );
  }

  return (
    <Box mt={2}>
      {Header}
      <Grid container columns={8} spacing={1.5}>
        {props.list.map((item, index) => {
          return (
            <Grid item xs={8} key={index}>
              <WinListItem
                onOpen={props.onOpen}
                data={item}
                current={props.current}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const WinListItem = (props: {
  data: BoxDto;
  current: number;
  onOpen: (ruleKey: number) => void;
}) => {
  return (
    <Box
      sx={{
        bgcolor: 'background.neutral',
        borderRadius: 1,
        px: 1.5,
        py: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box flex="auto" minWidth={0}>
          <Stack spacing={0.5} mr={2}>
            <Typography
              lineHeight="16px"
              variant="body2"
              color="text.secondary"
            >
              Já apostei
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 700,
                  color: 'white',
                  marginLeft: '2px',
                }}
              >
                {props.current}
              </span>
            </Typography>
            <LinearProgress
              variant="determinate"
              value={Math.floor(
                Math.min((props.current / props.data.targetInvite) * 100, 100),
              )}
              sx={{
                width: '100%',
                height: '6px',
                borderRadius: '4px',
                '& .MuiLinearProgress-bar': {
                  borderRadius: '4px',
                  backgroundColor:
                    props.data.status === BoxStatus.已开启
                      ? 'text.secondary'
                      : props.data.status === BoxStatus.待开启
                      ? 'primary.main'
                      : 'secondary.main',
                },
                // #FFFFFF8F
                backgroundColor: '#FFFFFF26',
              }}
            />
            <Typography variant="body2" color="text.secondary">
              Bet.{' '}
              <span style={{ fontSize: 14, color: 'white', fontWeight: 500 }}>
                {props.data.targetInvite}
              </span>
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            minWidth: 88,
          }}
        >
          {props.data.status === BoxStatus.已开启 && (
            <Box mb={0.5}>
              <img src={IMAGE_RECEIVED} width={20} height={20} alt="" />
            </Box>
          )}
          {props.data.status !== BoxStatus.已开启 && (
            <Box
              sx={Object.assign(
                {
                  boxShadow: 'none !important',
                  px: 1.5,
                  py: 1,
                  borderRadius: 0.5,
                  fontSize: 14,
                  lineHeight: '16px',
                  fontWeight: 700,
                  mb: 0.5,
                  cursor:
                    props.data.status === BoxStatus.待开启 ? 'pointer' : 'none',
                },
                props.data.status === BoxStatus.待开启
                  ? {
                      background:
                        'linear-gradient(96.63deg, #E58CDB 50%, #F1EE86 100%)',
                    }
                  : {
                      bgcolor: 'background.default',
                      color: 'text.secondary',
                    },
              )}
              onClick={() => {
                if (props.data.status === BoxStatus.待开启) {
                  props.onOpen(props.data.ruleKey);
                }
              }}
            >
              Receber
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="body2"
              lineHeight={1}
              fontWeight={500}
              fontStyle="italic"
            >
              R$
            </Typography>
            <Typography
              ml={'2px'}
              lineHeight={1}
              variant="subtitle2"
              fontWeight={700}
              fontStyle="italic"
            >
              {props.data.money}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
