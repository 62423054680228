import EmptyData from '@/components/EmptyData';
import Center from '@/components/center';
import RedeemDialog from '@/components/coupon/RedeemDialog';
import RedeemFailDrawer from '@/components/coupon/RedeemFailDrawer';
import RedeemResDialog from '@/components/coupon/RedeemResDialog';
import { useAuthJump } from '@/hooks/useAuthJump';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import Footer from '@/layouts/Footer';
import services from '@/services';
import { isOpenRedeeDialogState, selActivityState } from '@/stores/auth';
import { homeTab as homeTabState, promotionListState } from '@/stores/config';
import { ActivityType, HomeTab } from '@/types';
import { IBannerItem } from '@/types/config';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Typography } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import useScrollToTop from '../../hooks/useScrollToTop';

import IMAGE_TIMEOUT from '@/assets/images/network-timeout.png';
import { CommonLoading } from '@/components/SuspenseLoading';
import ImageWithLoading from '@/components/image';
import { globalMaxWidth } from '@/theme';

interface Props {}

const PromotionItem: FunctionComponent<{ item: any }> = (props) => {
  const navigate = useNavigate();
  const { item } = props;
  const [selActivity, setSelActivity] = useRecoilState(selActivityState);

  const { authJump } = useAuthJump();
  const { getValueByLanguage } = useLang();

  const width = Math.min(globalMaxWidth, window.innerWidth);
  const imgWidth = width - 32;

  const onPressInfo = () => {
    if (item.type === ActivityType.返水活动) {
      navigate('/promotion/money');
      return;
    }

    setSelActivity(item);

    if (item?.routePath) {
      const data: IBannerItem = {
        url: item?.routePath,
        target: item?.isOutRoute,
        group_id: 0,
        title: '',
        pic: '',
      };

      authJump(data);
      // if (item.routePath.startsWith('/deposit')) {

      // }

      // if (item?.isOutRoute === 1) {
      //   window.open(item?.routePath, '_blank');
      // } else if (isMobile) {
      //   if (item?.routePath.includes('/lucky')) {
      //   } else if (item?.routePath.includes('/commissions')) {
      //     setHomeTab(HomeTab.Invite);
      //   } else if (item?.routePath.includes('/vip')) {
      //     setHomeTab(HomeTab.Vip);
      //   } else if (item?.routePath.includes('/me')) {
      //     setHomeTab(HomeTab.Me);
      //   } else {
      //     navigate(item?.routePath);
      //   }
      // } else {
      //   if (item?.routePath.includes('/coupon')) {
      //     setIsOpenRedeeDialog(true);
      //   } else {
      //     navigate(item?.routePath);
      //   }
      // }
    } else {
      navigate(`/infomation/${item.id}`);
    }
  };

  return (
    <Box sx={{ px: 0, py: 0, '&:hover': 'none' }} onClick={onPressInfo}>
      <ImageWithLoading
        src={item.pic}
        width={imgWidth}
        height={(imgWidth * 128) / 343}
        aspectRatio="343/128"
        alt={getValueByLanguage(item.title)}
        style={{
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      />
    </Box>
  );
};

const PromotionsPage: React.FC<Props> = (props: Props) => {
  useScrollToTop();
  const { __ } = useLang();
  const location = useLocation();
  const [homeTab, setHomeTab] = useRecoilState(homeTabState);
  const [loading, setLoading] = useState<boolean>(true);
  const [promotionList, setPromotionList] = useRecoilState(promotionListState);
  const [open, setOpen] = useBoolean();
  const [resOpen, setResOpen] = useBoolean();
  const [failOpen, setFailOpen] = useBoolean();
  const [redeeData, setRedeeData] = useState<any[]>([]);
  const [redeeFailData, setRedeeFailData] = useState<any[]>([]);
  const [isOpenRedeeDialog, setIsOpenRedeeDialog] = useRecoilState(
    isOpenRedeeDialogState,
  );

  const [isTimeout, setIsTimeout] = useState<boolean>(false);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);

  const onRefresh = async () => {
    if (isRefresh) {
      return;
    }
    setIsRefresh(true);
    await fetchData();
    setIsRefresh(false);
  };

  const fetchData = async () => {
    const data = await services.config.getPromotionList();
    if (Array.isArray(data?.list)) {
      setPromotionList(data.list || []);
    }

    if (data?.code === 'ECONNABORTED' || data?.code === 'ERR_NETWORK') {
      setIsTimeout(true);
    } else {
      setIsTimeout(false);
    }

    setLoading(false);
  };
  useEffect(() => {
    if (homeTab === HomeTab.Bonus || location.pathname === '/promotions') {
      fetchData();
    }
  }, [homeTab]);

  const onRedeem = (data: any) => {
    setOpen.off();
    setIsOpenRedeeDialog(false);
    if (Array.isArray(data) && data?.length > 0) {
      setRedeeData(data);
      setResOpen.on();
    } else {
      setRedeeFailData(data?.ruleDetails || []);
      setFailOpen.on();
    }
  };

  return (
    <Box minHeight="calc(100vh - 56px)" pt={1} pb={2}>
      {/* <Center height={60} mx={-1.5}>
        <PageHeader onBack={() => navigate(-1)} title={__('Promotions')} />
      </Center> */}
      {/* <Typography variant="subtitle2" fontWeight={700} my={1}>
        {__('Promotions')}
      </Typography> */}
      {loading && promotionList.length === 0 ? (
        <Center height="calc(100vh - 116px)">
          <CommonLoading />
        </Center>
      ) : promotionList.length > 0 ? (
        <Grid container columns={24} spacing={'12px'}>
          {promotionList.map((v, i) => {
            return (
              <Grid item xs={24} key={i}>
                <PromotionItem item={v} key={i} />
              </Grid>
            );
          })}
        </Grid>
      ) : isTimeout ? (
        <Box mb={4}>
          <Box maxWidth="280px" mx="auto" textAlign="center" pt={3}>
            <img src={IMAGE_TIMEOUT} alt="" width={280} height={210} />
            <Typography mt={2} sx={{ maxWidth: '280px' }}>
              Tempo limite de acesso, atualize e tente novamente
            </Typography>
            <LoadingButton
              loading={isRefresh}
              variant="contained"
              onClick={onRefresh}
              sx={{
                mt: 3,
                width: '200px',
                height: '48px',
                borderRadius: '999px',
              }}
            >
              Refresh
            </LoadingButton>
          </Box>
        </Box>
      ) : (
        <Center py={10}>
          <EmptyData />
        </Center>
      )}
      <Footer />
      {!isMobile ? (
        <>
          <RedeemDialog
            open={open || isOpenRedeeDialog}
            onClose={() => {
              setOpen.off();
              setIsOpenRedeeDialog(false);
            }}
            onRedee={onRedeem}
          />
          <RedeemResDialog
            resData={redeeData}
            open={resOpen}
            onClose={setResOpen.off}
          />
          <RedeemFailDrawer
            open={failOpen}
            onClose={setFailOpen.off}
            list={redeeFailData || []}
          />
        </>
      ) : null}
    </Box>
  );
};

export default PromotionsPage;
