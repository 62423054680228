import IMAGE_BACK from '@/assets/buttons/back-button.png';
import { CommonLoading } from '@/components/SuspenseLoading';
import Center from '@/components/center';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import services from '@/services';
import { ColorfulBg } from '@/styles';
import { globalMaxWidth } from '@/theme';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { memo, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

interface Props {}

const GamePlayPage: React.FC<Props> = (props: Props) => {
  const navigete = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [backLoading, setBackLoading] = useState<boolean>(false);
  const [gameUrl, setGameUrl] = useState<string>('');
  const [open, setOpen] = useBoolean();
  const { __ } = useLang();
  const { getWallet } = useAuth();

  const historyLengthRef = useRef<number>(0);

  const [width, setWidth] = useState<number>(
    isMobile ? window.innerWidth : Math.min(window.innerWidth, globalMaxWidth),
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');
    if (id) {
      getGameUrl(id);
    }

    historyLengthRef.current = window.history.length;
    console.log(
      '页面进入 window.history',
      window.history,
      historyLengthRef.current,
    );
  }, []);

  const handleBack = async () => {
    console.log('点击返回 window.history', window.history);

    setBackLoading(true);

    getWallet();

    document.getElementById('gameIframe')?.remove();
    // setBackLoading(false);
    // setGameUrl('');
    // setOpen.off();
    // const params = new URLSearchParams(window.location.search);
    // const id = params.get('id');
    // navigete('');
    // if (window.opener) {
    //   window.close();
    // } else {
    // }
    const nowHistoryLength = window.history.length;
    const diff = nowHistoryLength - historyLengthRef.current;

    if (diff > 0) {
      navigete(-(diff + 1));
    } else {
      navigete(-1);
    }
  };

  const onIframeLoad = () => {
    setLoading(false);
  };

  const getGameUrl = async (id: string) => {
    const res = await services.game.getGameUrl({ game_id: +id });
    if (res?.data) {
      setGameUrl(res?.data);
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          height: '100%',
          minHeight: '100%',
          maxHeight: '100vh',
          position: 'relative',
        }}
      >
        {/* 返回按钮 */}
        <IconButton
          sx={{
            position: 'absolute',
            top: '40px',
            left: '12px',
          }}
          color="default"
          onClick={() => {
            setOpen.on();
          }}
        >
          <img src={IMAGE_BACK} alt="back" width={36} height={36} />
        </IconButton>

        {loading ? (
          <Center
            style={{
              width: `${width}px`,
              height: '100vh',
              verticalAlign: 'middle',
            }}
          >
            <CommonLoading />
          </Center>
        ) : null}

        <GameIframe src={gameUrl} onLoad={onIframeLoad} />
      </Box>
      {/* 退出弹窗 */}
      <Dialog onClose={setOpen.off} open={open}>
        <Box
          py={3}
          px={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="subtitle1" fontWeight={800}>
            {__('Are you sure to exit?')}
          </Typography>
        </Box>

        <Box px={2} pb={2}>
          <Stack width="100%" direction="row" alignItems="center" spacing={1.5}>
            <Button
              fullWidth
              variant="outlined"
              size="small"
              onClick={setOpen.off}
              sx={{
                height: '48px',
                width: '132px',
                color: '#ffffff',
                borderColor: '#323D4D',
              }}
            >
              {__('Cancel')}
            </Button>
            <LoadingButton
              fullWidth
              sx={{ background: ColorfulBg(), height: '48px', width: '132px' }}
              variant="contained"
              size="small"
              loading={backLoading}
              onClick={handleBack}
            >
              {__('Sure')}
            </LoadingButton>
          </Stack>
        </Box>
      </Dialog>
    </Box>
  );
};

export default GamePlayPage;

const GameIframe = memo((props: { src: string; onLoad: () => void }) => {
  const gameRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    if (gameRef.current) {
      gameRef.current.style.height = window.innerHeight + 'px';

      if (isMobile) {
        gameRef.current.style.width = window.innerWidth + 'px';
        gameRef.current.style.maxWidth = '100%';
      } else {
        gameRef.current.style.width =
          Math.min(window.innerWidth, globalMaxWidth) + 'px';
      }
    }
  }, [props.src]);

  useEffect(() => {
    function handleResize() {
      if (gameRef.current) {
        gameRef.current.style.height = window.innerHeight + 'px';

        if (isMobile) {
          gameRef.current.style.width = window.innerWidth + 'px';
          gameRef.current.style.maxWidth = '100%';
        } else {
          gameRef.current.style.width =
            Math.min(window.innerWidth, globalMaxWidth) + 'px';
        }
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    function handleOrientationChange() {
      if (gameRef.current) {
        gameRef.current.style.height = window.innerHeight + 'px';

        if (isMobile) {
          gameRef.current.style.width = window.innerWidth + 'px';
          gameRef.current.style.maxWidth = '100%';
        } else {
          gameRef.current.style.width =
            Math.min(window.innerWidth, globalMaxWidth) + 'px';
        }
      }
    }

    window.addEventListener('orientationchange', handleOrientationChange);
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  if (!props.src) {
    return null;
  }

  return (
    <iframe
      ref={gameRef}
      onLoad={props.onLoad}
      title="game"
      src={props.src}
      frameBorder={0}
      id="gameIframe"
      style={{
        width: '100%',
        maxWidth: `${globalMaxWidth}px`,
        height: '100vh',
        verticalAlign: 'middle',
      }}
    ></iframe>
  );
});
