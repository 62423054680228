export const pt = {
  // 通用
  Save: 'Salvar',
  Success: 'Sucesso',
  Successfully: 'Com sucesso',
  Edit: 'Editar',
  More: 'Mais',
  'Load More': 'Carregar mais',
  'Nothing more': 'Nada mais',
  'No more': 'Não mais',
  Search: 'Buscar',
  Cancel: 'Cancelar',
  Sure: 'Certeza',
  Submit: 'Enviar',
  No: 'Não',
  Yes: 'Sim',

  Empty: 'Vazio',
  Receive: 'Receber',
  // 登录
  '您还未绑定过账号，请先注册并自动绑定': `Você ainda não vinculou sua conta. Por favor, registre-se primeiro e vincule automaticamente.`,
  登录: 'Entrar',
  Phone: 'Telefone',
  Email: 'E-mail',
  'Please enter email': 'Por favor, insira o e-mail',
  'Please enter phone number': 'Por favor, insira o número de telefone',
  'Send Code': 'Enviar código',
  'Please enter verify code': 'Por favor, insira o código de verificação',
  密码登录: 'Via senha',

  验证码登录: 'Via código',
  'Forgot password?': 'Esqueceu a senha?',
  'Privacy Policy and Terms': `Correto: Este site oferece jogos com experiência de risco. Para ser um usuário do nosso site, você deve ser maior de 18 anos. Não somos responsáveis por percas de bens.`,
  'Not registered? ': 'Não registrado?',
  'Join us': 'Junte-se a nós',
  // 注册
  'Sign Up': 'Inscrever-se',
  密码不一致: 'Senha inconsistente',
  'Enter new password': 'Digite a nova senha',
  'Enter new password Again': 'Digite a nova senha novamente',
  // 重置密码
  'Forgot Password': 'Esqueceu a senha?',
  'Successfully reset password': 'Senha redefinida com sucesso',
  'Can not receive code?': 'Não consegue receber o código?',
  'Click to contact online customer service': `Clique para entrar em contato com o serviço de atendimento ao cliente online`,
  // 历史记录
  History: 'Histórico',
  资金变更: 'Alterações de financiamento',

  存款: 'Depósito',
  取款: 'Retirar',
  游戏记录: 'Registros de jogos',
  佣金: 'Comissão',
  流水: 'Volume de apostas',
  盈亏: 'Lucro/Perda',
  条投注记录: '{n} registros de apostas',
  '余额:': 'Saldo:',
  // 我的页面
  '1 Day': '1 Dia',
  '2 Day': '2 Dias',
  '3 Day': '3 Dias',
  '4 Day': '4 Dias',
  '5 Day': '5 Dias',
  '6 Day': '6 Dias',
  '7 Day': '7 Dias',
  每日签到: 'Check-in diário',
  立即签到: 'Fazer check-in agora',
  断更将从第1天开始领取: `As atualizações descontinuadas serão coletadas a partir do primeiro dia`,
  签到成功: 'Check-in realizado com sucesso',
  'ID: ': 'ID: ',
  'My Wallet': 'Minha carteira',
  提取: 'Retirar',
  Account: 'Conta',
  'Live Support': 'Atendimento ao cliente 24H',
  Setting: 'Configuração',
  'Sign Out': 'Sair',
  '是否确定退出？': 'Tem certeza que deseja sair?',
  'Sign out successfully': 'Saiu com sucesso',
  // VIP页面
  'Current Level': 'Nível atual',
  完成以下任务即可升级到: 'Complete as seguintes tarefas para atualizar para:',
  打码量: 'Volume de apostas',
  充值次数: 'Número de depósitos',
  次: '{n} vezes',
  充值金额: 'Valor do depósito',
  Privilege: 'Privilégio',
  每日最大提款: 'Retirada máxima por dia',
  周礼金: 'Bônus semanal',
  月礼金: 'Bônus mensal',
  Bonus: 'Promoções',
  保级要求: 'Requisitos de Retenção',
  保级充值金额: 'Valor do Depósito de Retenção',
  到期: 'Vencimento YYYY-MM-DD',
  保级天数: 'Dias de Retenção',
  // 设置
  资金密码: 'Senha de Pagamento',
  'Set Payment Password': 'Definir Senha de Pagamento',
  'set payment tips': `Somente letras, números e símbolos comuns podem ser usados. O comprimento mínimo é de 4 bits e o máximo é de 32 bits.`,
  'Edit Payment Password': 'Editar Senha de Pagamento',
  'Enter current password': 'Digite a senha atual',
  // 第三方账号绑定
  绑定成功: 'Vinculado com sucesso',
  绑定: 'Vincular',
  解绑成功: 'Desvinculado com sucesso',

  解绑: 'Desvincular',
  '是否确定解绑？': 'Tem certeza de que deseja desvincular?',
  // 充值
  moneyLimit: 'O valor não pode ser maior que {max} nem menor que {min}',
  Deposit: 'Depósito',
  支付方式: 'Método de Pagamento',
  请输入或选择存款金额: 'Por favor, insira ou selecione o valor do depósito',
  立即存款: 'Depositar Agora',
  // 游戏分类页面
  Providers: 'Provedores',
  Popular: 'Popular',
  // 搜索页面
  'Please enter': 'Por favor, insira',
  // 钱包
  Wallet: 'Carteira',
  钱包总额: 'Carteira Total',
  剩余打码金额: 'Volume Restante de Apostas',
  充值: 'Depositar',
  // 游戏详情
  'Real Mode': 'Modo Real',
  'Are you sure to exit?': 'Tem certeza de que deseja sair?',
  Recommended: 'Recomendado',
  // 邀请
  Commissions: 'Comissões',
  Members: 'Membros',
  Log: 'Registro',
  'Copy to clipboard': 'Copiar para a área de transferência',
  'Share to': 'Compartilhar para',
  'share link': 'compartilhar link',
  CommissionsMoney: 'Comissões dos Primeiros Depósitos (Atual): R$ {n}',
  'Rule description': 'Regras de atividades',
  Example: 'Exemplo',
  Bets: 'Apostas',
  Proportion: 'Proporção',
  Earn: 'Ganhar',
  rule1: `Sua comissão = comissão do nível 1 + comissão do nível 2 + comissão do nível 3`,
  rule2: `Comissão do nível 1 = (A1 apostas acumuladas + B1 apostas acumuladas...) x0.3%`,
  rule3: `Comissão do nível 2 = (A21+A22+B21+...+B22... todas as apostas acumuladas) x0.2%`,
  rule4: `Comissão do nível 3 = (A31+A32+B31+...+B32... todas as apostas acumuladas) x0.1%`,
  'Exemple:': 'Exemplo:',
  rule5: 'Apostas acumuladas no nível 1 = 100.000',
  rule6: 'Apostas acumuladas no nível 2 = 200.000',
  rule7: 'Apostas acumuladas no nível 3 = 200.000',
  rule8: 'Seu ganho: 100.000x0,3% + 200.000x0,2% + 200.000x0,1% = R$900',
  结束时间不能小于开始时间: `O horário de término não pode ser anterior ao horário de início`,
  ID: 'ID',
  'Invited Date': 'Data do Convite',
  Deposits: 'Depósitos',
  'Vald Bets': 'Apostas Válidas',
  累计获得佣金: 'Comissão Cumulativa Ganha',
  条数据: 'Total de {n} registros',
  'Game Providers': 'Provedores de Jogos',
  // 首页-游戏分类
  fish: 'Peixe',
  hot: 'Quente',
  like: 'Curtir',
  live: 'Ao Vivo',
  recent: 'Recente',
  recommend: 'Recomendar',
  slots: 'Caça-níqueis',
  // 回到顶部
  'Back to top': 'Voltar ao topo',
  // 页头
  'Log in': 'Entrar',
  // 页脚
  Suggestion: 'Sugestão',
  'Help center': 'Centro de Ajuda',
  'Terms and conditions': 'Termos e Condições',
  'Privacy Policy': 'Política de Privacidade',
  'Contact us': 'Entre em Contato',
  copyright: '© 2024 {host} | Todos os Direitos Reservados.',
  // 提现
  '请绑定CPF后再选择提现。': `Por favor, vincule o CPF antes de selecionar a retirada.`,
  'Safety Verification': 'Verificação de Segurança',
  'Enter CPF': 'Digite o CPF',
  'Enter Phone': 'Digite o Telefone',
  'Enter CPF Phone': 'Digite o CPF Telefone',
  'Enter Email': 'Digite o e-mail',
  'Enter You First Name': 'Digite Seu Primeiro Nome',
  'Enter You Last Name': 'Digite Seu Último Nome',
  Birthday: 'Data de Nascimento',
  绑定手机: 'Vincular Telefone',
  请输入资金密码: 'Por favor, digite a senha de pagamento',
  显示提现帐号: 'Mostrar conta de retirada',
  全部提现: 'Todas as Retiradas',
  填写资金密码: 'Digite a senha de pagamento',
  提现: 'Retirada',
  温馨提示: 'Dicas',
  '您还没有设置资金密码，是否立即设置？': `Você ainda não definiu a senha de pagamento. Deseja defini-la agora?`,
  去设置: 'Ir para definir',
  gameName: 'Nome do Jogo',
  'Risk Warning': 'Aviso de Risco',
  'Risk Warning Content': `Nós oferecemos jogos com uma experiência de aventura. Para se tornar um usuário do nosso site, você deve ter pelo menos 18 anos de idade. Não somos responsáveis por qualquer violação das leis locais referentes ao jogo online. Por favor, assegure-se de que o seu jogo seja controlado.`,
  nProviders: '{n} Provedores',
  'Select deposit amount': 'Selecione o valor do depósito',
  'Already have an account?': 'Já tem uma conta?',
  'Log in now': 'Entrar agora',
  displaying: '{x} registros carregados',
  Information: 'Informação',
  Promotions: 'Promoções',
  提款方式: 'Método de Retirada',
  提款信息: 'Informação de Retirada',
  wallettip1: 'É necessário apostar ',
  wallettip2: ' para fazer sua retirada',
  dailySignTitle1: 'Registrado para',
  dailySignTitle2: 'dias consecutivos',
  'Sign in': 'Entrar',
  'Signed in': 'Registrado',
  Today: 'Hoje',
  'Sign In Successfully': 'Entrada com Sucesso',
  'Rate of Rebate': 'Taxa de Reembolso',
  rebateTip: `A senha do fundo é usada para retirada de dinheiro e pode conter letras, números e símbolos comumente usados. O comprimento mínimo é de 4 dígitos e o máximo é de 32 dígitos.`,
  messages: 'Mensagens',
  'Messages Detail': 'Detalhes das Mensagens',
  'Winning Ranking': 'Classificação de Vitórias',
  Rank: 'Classificação',
  Player: 'Jogador',
  Profit: 'Lucro',
  delFail: 'Falha ao excluir, por favor, tente novamente',
  'Winning Records': 'Registros de Vitórias',
  'Invite Ranking': 'Classificação de Convites',
  Record: 'Registro',
  Time: 'Tempo',
  'Select A Channel': 'Selecione um Canal',
  'Select Coupon (n)': 'Selecionar Cupom ({n})',
  Coupon: 'Cupom',
  Congratulations: 'Parabéns!',
  signSuccessTip: 'Você entrou com sucesso e ganhou o prêmio',
  signDay: '{n} Dia',
  'Contact Platform': 'Plataforma de Contato',
  Use: 'Usar',
  'Expires in': 'Expira em {time}',
  Expired: 'Expirado',
  register: 'Registrar',
  login: 'Login',
  'Register And Sign In': 'Registrar e Entrar',
  'Game Recommendations': 'Recomendações de Jogos',
  'View All': 'Ver Todos',
  Play: 'Jogar',
  completeTask: '*Complete as tarefas acima para atualizar para ',
  'Relegation Task': 'Tarefa de Rebaixamento',
  completedDate: `A missão de rebaixamento foi concluída! A próxima tarefa começará em {date}.`,
  unCompletedDate: `Se completado antes de {date}, o rebaixamento será bem-sucedido, caso contrário, será rebaixado.`,
  登录密码: 'Senha de Login',
  'Edit Login Password': 'Editar Senha de Login',
  Reward: 'Recompensa',
  'Invitation Code (Optional)': 'Código de Convite (Opcional)',
  'Como instalar?': 'Como instalar?',
  'account lock tip': `Suspenda temporariamente sua conta de todas as atividades de apostas por um período de tempo determinado. Aviso! Uma vez que sua conta tenha sido suspensa, você terá que esperar o término da suspensão ou entrar em contato com o suporte para revogar a suspensão.`,
  'Suspension Days': 'Dias de Suspensão',
  'Current Password': 'Senha Atual',
  Verify: 'Verificar',
  'Change Password': 'Alterar Senha',
  'Phone Verification': 'Verificação por Telefone',
  'Email Verification': 'Verificação por Email',
  'login tip': `{type} não registrados serão diretamente registrados e conectados para você após a verificação.`,
  Transfer: 'Transferir',
  'Transfer tip': 'Transferir para o Saldo da Conta',
  Amount: 'Quantidade',
  Message: 'Mensagem',
  'Game Num': '{num}/{all} registros carregados',
  'Enter your amount': 'Minimo:{min}，Máximo:{max}',
  'Mobile phone number': 'Número de telefone celular',
  'email address': 'endereço de e-mail',
  'commission tip1': `A cada membro válido que você convidar, você receberá um bônus de R$[min] a [max] reais. Ao convidar 30 membros válidos, você atualizará automaticamente para a V2. `,
  'commission tip1-0': `O que é um membro válido?`,
  'commission tip1-1': `1. Recarga acumulada de 20 reais ou acima.`,
  'commission tip1-2': `2. Aposta acumulada 200 ou acima.`,
  'commission tip2': `Após a atualização, você poderá obter equipes de primeiro, segundo e terceiro nível. `,
  'commission tip3': `Comissão: O horário de liquidação da comissão da equipe é às 2h do dia seguinte e o sistema irá liquidá-la automaticamente.`,
  'Join the telegram group, there will be surprises every day': `Obtenha o código de resgate de bônus`,
  'Join in': 'Junte-se',
  Spinner: 'Roleta',
  Casino: 'Cassino',
  Invite: 'Convite',
  ME: 'EU',
  Recharge: 'Recarga',
  'Bet Amount': 'Valor da aposta',
  Level: 'Nível',
  Depositor: `Depositante`,
  First: `Primeiro`,
  Second: `Segundo`,
  Third: `Terceiro`,
  Records: `Registros`,
  Task: `Task`,
  General: `General`,
  Settings: `Settings`,
  绑定社交账号: `Bind social account`,
  '充值&提款': `Deposit & Withdrawal`,
  'Enter your password': `Coloque sua senha`,
  'This site is subject to recaptcha and Google Privacy Policy and Terms Of Service apply.': `This site is subject to recaptcha and Google Privacy Policy and Terms Of Service apply.`,
  'Withdrawable Amount': 'Valor Sacável Level - Nível',
  'Withdrawal Fee': 'Taxa de retirada',
  'The minimum withdrawal value is': 'O valor mínimo de saque é',
  Start: 'Começar',
  'Winning Record': 'Recorde de vitórias',
  'box num': 'Você tem [num] caixas cegas',
  Rewards: 'Recompensas',
  Lobby: 'Salão',
  'invite Tip': 'Convide membros válidos e você receberá bônus ',
  rentouMoney: 'R$[money]',
  'Box Tip':
    'Caros usuários VIP, você pode obter o número de sorteios da roleta de loteria das 3 maneiras a seguir:',
  'Box Tip1':
    '1. Se você recarregar 100 em um único dia, poderá ganhar 1 sorteio de loteria e até 5 sorteios de loteria todos os dias.',
  'Box Tip2':
    '2. Se você convidar 2 membros válidos em um único dia, poderá obter 1 sorteio e até 10 sorteios todos os dias.',
  'Box Tip3':
    '3. Se você apostar 1.000 cumulativamente em um único dia, poderá obter 1 sorteio e até 5 sorteios todos os dias.',
  'The following data is updated every 10 minutes':
    'Os dados abaixo são atualizados a cada 10 minutos',
  'lucky draw': 'Loteria',
  'How to get the number of draws': 'Como obter o número de loteria',
  'Withdrawal fee': 'Taxa de retirada',
  'Rebate range': 'Faixa de rebate',
  mTip1: '1. O subordinado acumulou recargas de 10 ou acima. ',
  mTip2: '2. O subordinado acumulou apostas de 100 ou acima.',
  vipTip1:
    '1.Cada depósito precisa passar por uma aposta de Compensação de valor. ',
  vipTip2:
    '2.Você precisa fazer a aposta necessária para que seu saldo fique retirável. Logo em seguida será convertida em saldo retirável. ',
  vipTip3: '3.Os dados retirável é atualizado a cada 10 minutos.',
  'Incorrect email format': 'Formato incorrecto do e-mail',
  'please choose withdraws type': 'por favor escolha o tipo de retirada',
  withTip: 'mínimo:30,Máximo:20000',
  // 1.2 新增
  '首次充值（只有一次机会）': 'Não participe de promoções',
  首充福利: 'Benefício de Primeiro depósito: ',
  paster: 'Colar',
  首次充值可额外获得xx奖金:
    'Você pode obter um bónus adicional de {xx} para o primeiro depósito. Só há uma chance. Você só pode selecionar uma das várias opções.',
  tabInvite: 'Convite',
  tabStatistics: 'Estatísticas',
  tabLog: 'Bônus',
  'Exclusive Invitation Link': 'Seu link de convite exclusivo',
  Copy: 'Copiar',
  'Rewards&Commissions': 'Bônus de convite',
  'Open treasure chest': 'baús de tesouro',
  'Win commission': 'Ganhe comissão',
  'Mission conditions': 'Condições da missão',
  'recharge reaches': 'Valor de recarga',
  'Cumulative bets reached': 'Apostas cumulativas alcançadas',
  Historical: 'Histórico',
  'Total Data': 'totais de dados',
  'Total Cumulative Prize Money': 'Total de prêmios obtidos',
  'Treasure Chest': 'Detalhes',
  'Opening Data': 'de convite',
  'Completed invitation conditions': 'Convite Concluído',
  'Bounty obtained': 'Recompensa Obtida',
  Compeleted: 'Concluído',
  'In Progress': 'Em andamento',
  'Accumulated recharge': 'Recarga acumulativas',
  'Cumulative bets': 'Aposta acumulativas',
  'Data For Inviting': 'Dados de convites',
  'New Players': 'novos jogadores',
  'Numbers of new members': 'Números de novos membros',
  'Valid direct invitations': 'Convites diretos válidos',
  'No Reward Required': 'Não preciso do bônus',
  boxTip1:
    '1.Se seu convidado completar a tarefa especificada de apostas você pode abrir o baú do tesouro e receber sua recompensa.',
  boxTip2: '2.Os dados de convite são atualizados a cada 10 minutos',
  Expand: 'Expandir',
  Collapse: 'Fechar',
  'For every new active member you invite, you get a percentage of the bonus.':
    'Convide amigos e receba por apostas em equipe e recompensas de comissões.',
  // 1.4
  'Cooperating Partners': 'Parceiros de Cooperação',
  'Redemption failed': 'Falha no resgate',
  'Redeem when conditions are met':
    'Resgatar quando as condições forem atendidas',
  'same tip':
    'Você já participou deste evento. Contas cadastradas no mesmo dispositivo só poderão participar de 1 vez do evento!',
  agentSupportTitle:
    'Para cooperar como agente, entre em contato com o atendimento ao cliente.',
  'Payment confirmation': 'pagamento confirmado',
  'Need to pay': 'precisa pagar',
  'Balance payment': 'Pagamento de saldo',
  ymoney: 'Dinheiro ',
  'Insufficient balance': 'Saldo insuficiente.',
  // 下面未翻译
  'Download APP': 'Baixar APP',
  'Login or Register': 'Entrar ou Registo',
  'Remember account password': 'Lembre-se da senha da conta',
  pricayTip:
    'Aceito a política de dados e ao acessar o site, eu confirmo ter 18 anos.',
  'Histórico de convites': 'Histórico de convite',
  'Histórico de convites válidos:': 'Histórico de convites válidos:',
  'Histórico Efetivo da Primeira Carga': 'Primeira recarga direta',
  'Valor de recarga da equipe': 'Valor de recarga da equipe',
  'Apostas da equipe': 'Apostas da equipe',
  'Redeem Now': 'Resgatar Agora',
  'Gift code': 'Código de presente',
  'Enter your code': 'Insira seu código',
  'Copied successfully': 'Cópia concluída',
  Restricted: 'Restrito',
  'Amount Recharge': 'Valor da recarga',
  'Package Purchase': 'Aderir pacotes',
  'Total received': 'Valor total',
  'Rule details': 'Detalhes da regra',
  'Total reception': 'Valor total',
  'Daily reception': 'Valor diário recebido',
  'First reception': 'Primeiro valor recebido',
  Only: 'Compre por',
  'Comunidade oficial recomendada': 'Participe dos canais oficiais',
  'As informações mais recentes e rápidas':
    'E receba promoções exclusivas rapidamente ',
  day: 'dia',
  Received: 'Recebido',
  Uncollected: 'Não coletado',
  'Already obtained': 'Já recebido',
  Discontinue: 'Discontinue',
  'Temporarily dismantle': 'Evento acabou',
  'Get rewarded': 'Receber prêmio',
  'Claim failed': 'Falha ao coletar',
  'W kolejce do odbioru': 'Pagamento bem-sucedido, processamento...',
  'minTip':"A recarga mínima na plataforma é de {min} reais. Após a recarga, o sistema descontará diretamente 1 real.",
};
